"use strict";
/*!
 *
 *  tab.js
 *
 */
var tabContents = /** @class */ (function () {
    function tabContents(elem, opt) {
        var _this = this;
        this.hasHashCurrent = false;
        this.$nav = $(elem);
        this.setting = $.extend({}, this.def, opt);
        this.hash = util.hash;
        this.$a = this.$nav.find('a');
        this.$contents = this.$nav.next('.' + this.setting.cntClass);
        this.$a.each(function (i, item) {
            var _href = $(item).attr('href');
            if (_href === _this.hash) {
                _this.hasHashCurrent = true;
            }
            ;
        });
        this.setDefault();
        this.setEvent();
    }
    tabContents.prototype.setDefault = function () {
        var _this = this;
        var $link;
        var $contents;
        this.$a.each(function (i, elem) {
            var _href = $(elem).attr('href');
            if (_this.hasHashCurrent) {
                if (_href === util.hash) {
                    $link = $(elem);
                    $contents = $(_href);
                }
                ;
            }
            else {
                if (i === 0) {
                    $link = $(elem);
                    $contents = $(_href);
                }
                ;
            }
            ;
        });
        this.$a.attr('aria-selected', 'false');
        this.$contents.children().attr('aria-hidden', 'true');
        $link.attr('aria-selected', 'true');
        $contents.attr('aria-hidden', 'false');
        if (this.hasHashCurrent) {
            $(window).on('load', function () {
                history.replaceState(null, null, util.hash);
            });
        }
        ;
    };
    tabContents.prototype.setEvent = function () {
        var _this = this;
        this.$a.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            if ($(e.currentTarget).is('[aria-selected="true"]'))
                return false;
            var _href = $(e.currentTarget).attr('href');
            _this.$nav.find('[aria-selected="true"]').attr('aria-selected', 'false');
            _this.$contents.find('[aria-hidden="false"]').attr('aria-hidden', 'true');
            $(e.currentTarget).attr('aria-selected', 'true');
            $(_href).attr('aria-hidden', 'false');
        });
    };
    return tabContents;
}());
;
$.fn.tab = function (options) {
    var opt = options ? options : {};
    if (this.length > 0) {
        return this.each(function () {
            new tabContents(this, opt);
        });
    }
    ;
};
