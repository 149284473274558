"use strict";
/*!
 *
 *  linker.js
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1(option) {
        var _this = this;
        this.setting = {
            icon: {
                blank: 'blank',
                excel: 'xls',
                word: 'doc',
                pdf: 'pdf'
            },
            blankDir: undefined,
            extendLink: '.extend-link',
            notTarget: ''
        };
        this.domain = document.domain;
        $.extend(true, this.setting, option);
        this.setIcon();
        $(document).delegate('a', 'click', function (e) {
            if ($(e.currentTarget).is(_this.getTarget())) {
                e.preventDefault();
                var href = $(e.currentTarget).attr('href');
                window.open(href, '_blank');
            }
            ;
        });
        $(this.setting.extendLink).on('click', function (e) {
            e.preventDefault();
            var href = $(e.currentTarget).find('a').attr('href');
            var target = $(e.currentTarget).find('a').attr('target');
            if (target === undefined) {
                target = '_self';
            }
            ;
            window.open(href, target);
            return false;
        });
    }
    ;
    default_1.prototype.getTarget = function () {
        return 'a[target="_blank"]:not(' + this.setting.notTarget + '),' +
            'a[href^="http://"]:not([href*="' + this.domain + '/"]):not(' + this.setting.notTarget + '),' +
            'a[href^="https://"]:not([href*="' + this.domain + '/"]):not(' + this.setting.notTarget + '),' +
            this.getBlankDirTarget() +
            'a[href*=".pdf"]:not(' + this.setting.notTarget + '),' +
            'a[href*=".xls"], a[href*=".xlsx"]:not(' + this.setting.notTarget + '),' +
            'a[href*=".doc"], a[href*=".docx"]:not(' + this.setting.notTarget + ')';
    };
    default_1.prototype.getBlankDirTarget = function () {
        if (this.setting.blankDir !== undefined) {
            var target = '';
            for (var prop in this.setting.blankDir) {
                target += 'a[href ^= "/' + this.setting.blankDir[prop] + '/"],';
                target += 'a[href ^= "' + this.domain + '/' + this.setting.blankDir[prop] + '/"],';
            }
            ;
            return target.slice(0, -1);
        }
        else {
            return '';
        }
        ;
    };
    default_1.prototype.setIcon = function () {
        var _this = this;
        $(this.getTarget()).each(function (i, elem) {
            var href = $(elem).attr('href');
            if (href !== undefined && !(!$(elem).closest('.mc-Gallery').length && $(elem).find('img').length)) {
                if (href.search(/\.pdf/) > -1) {
                    $(elem).addClass(_this.setting.icon.pdf);
                }
                else if (href.search(/\.xls/) > -1 || href.search(/\.xlsx/) > -1) {
                    $(elem).addClass(_this.setting.icon.excel);
                }
                else if (href.search(/\.doc/) > -1 || href.search(/\.docx/) > -1) {
                    $(elem).addClass(_this.setting.icon.word);
                }
                else {
                    $(elem).addClass(_this.setting.icon.blank);
                }
                ;
            }
            ;
        });
    };
    return default_1;
}());
exports.default = default_1;
