/*!
 *
 * swipe-elem.js
 *
 */

//var isTouchDevice = ('ontouchstart' in window);

$.SwipeElem = function(_this, _opt) {
	this.$target = $(_this);
	this.opt = $.extend({}, this.settings, _opt);
	this.init();
};

$.SwipeElem.prototype = {
	init: function() {
		this.build();
		this.event();
		this.setIcon();
	},
	build: function() {
		this.$target.css('min-width', this.opt.width);
		this.$target.wrap('<div class="' + this.opt.elemClass + '"><div class="' + this.opt.elemClass + '_Contents"></div></div>');
		this.$elem = this.$target.closest('.' + this.opt.elemClass);
		this.$wrap = this.$target.closest('.' + this.opt.elemClass + '_Contents');
		this.$elem.prepend('<div class="' + this.opt.elemClass + '_Icon"></div>');
		this.$icon = this.$elem.children('.' + this.opt.elemClass + '_Icon');
		if (this.$target.data('icon') === 'top') {
			this.$icon.addClass( this.opt.elemClass + '_Icon' + '-top');
		};

		if( this.$target.children('caption').length ){
			const cap = this.$target.children('caption').html();
			this.$elem.before('<div class="' + this.opt.elemClass + '_Caption">'+ cap +'</div>');
		};

		this.$wrap.find('img').on('mousedown wrap', function(e) {
			e.preventDefault();
		});
	},
	event: function() {
		var self = this,
			timer = null;

		this.scrolling = false;

		$(window).on('resize', function(){
			self.setIcon();
		});

		this.$wrap.on('scroll', function(e) {
			self.scrolling = true;
			self.$icon.removeClass(self.opt.elemClass + '_Icon-visible');
		});
	},
	setIcon: function(){
		if( this.opt.width > this.$wrap.innerWidth() ){
			this.scrolling = false;
			this.$icon.addClass(this.opt.elemClass + '_Icon-visible');
		}else {
			this.scrolling = true;
			this.$icon.removeClass(this.opt.elemClass + '_Icon-visible');
		};
	},
	settings: {
		width: 890,
		elemClass: 'sw-Swipe'
	}
};

$.fn.swipeElem = function(options) {
	var _opt = options ? options : {};
	return this.each(function() {
		new $.SwipeElem(this, _opt);
	});
};