"use strict";
/*!
 *
 *  menu.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        var _this = this;
        this.$head = $('#MenuBtn');
        this.$body = $('#MenuBody');
        this.$head.on('click', function (e) {
            e.preventDefault();
            $(e.currentTarget).toggleAria('selected');
            _this.$body.toggleAria('expanded');
        });
    }
    return default_1;
}());
exports.default = default_1;
;
