"use strict";
/*!
 *
 *  toggle-aria.ts
 *
 */
$.fn.toggleAria = function (type) {
    return this.each(function (i, elem) {
        var $elem = $(elem);
        var types = type.split(' ');
        types.forEach(function (_type, i) {
            var aria = 'aria-' + _type;
            var val = $elem.attr(aria);
            if (val === 'true') {
                $elem.attr(aria, 'false');
            }
            else {
                $elem.attr(aria, 'true');
            }
            ;
        });
    });
};
