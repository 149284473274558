"use strict";
/*!
 *
 *  toggle-menu.ts
 *
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var toggler_1 = require("toggler");
var toggleExpander = /** @class */ (function (_super) {
    __extends(toggleExpander, _super);
    function toggleExpander(elem, option, $elems) {
        var _this = _super.call(this, elem, option) || this;
        _this.$elems = $elems;
        return _this;
    }
    toggleExpander.prototype.setEvent = function () {
        var _this = this;
        this.$head.on('click', function (e) {
            _this.$elems.not(_this.$elem).trigger('close');
            _this.expand(e);
        });
        this.$head.on('keydown', function (e) {
            if (e.keyCode === 13) {
                _this.$elems.not(_this.$elem).trigger('close');
                _this.expand(e);
            }
            ;
        });
        this.$elem.on('close', function (e) {
            _this.$head.removeClass(_this.headExpandClass);
            _this.$body.removeClass(_this.bodyExpandClass);
        });
    };
    return toggleExpander;
}(toggler_1.Toggler));
$.fn.toggleExpander = function () {
    var $elems = this;
    $(document).on('click', function (e) {
        if (!$(e.target).closest($elems.selector).length) {
            $elems.trigger('close');
        }
        ;
    });
    $(document).on('keydown', function (e) {
        if (e.keyCode === 13) {
            if (!$(e.target).closest($elems.selector).length) {
                $elems.trigger('close');
            }
            ;
        }
        ;
    });
    return this.each(function (i, elem) {
        var cl = $(elem).attr('class');
        var head = '.' + cl + '_Label';
        var body = '.' + cl + '_Body';
        new toggleExpander(elem, {
            head: head,
            body: body
        }, $elems);
    });
};
