"use strict";
/*!
 *
 *  search.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var $search = $('#Search');
var $btn = $('#SearchBtn');
var $keywords = $('#SearchKeywords');
exports.default = (function () {
    $btn.on('click', function (e) {
        if ($search.is('[aria-expanded="false"]')) {
            e.preventDefault();
            $search.attr('aria-expanded', 'true');
        }
        else {
            if ($keywords.val() === '') {
                e.preventDefault();
                $search.attr('aria-expanded', 'false');
            }
            ;
        }
        ;
    });
});
