"use strict";
/*!
 *
 *  megamenu.ts
 *
 */
var MegaMenu = /** @class */ (function () {
    function MegaMenu(elem) {
        this.$elem = $(elem).filter(function (i, elem) {
            if ($(elem).children('.st-Global_Body').length) {
                return true;
            }
            else {
                return false;
            }
            ;
        });
        this.$switch = this.$elem.find('.st-Global_Switch');
        this.$pmSwitch = this.$elem.find('.pm-Switch');
        this.$innerLinks = this.$elem.children('.st-Global_Body').find('a');
        //this.setHeight();
        this.setEvent();
    }
    // setHeight(): void {
    // 	this.$elem.each((i, elem) => {
    // 		let h = $(elem).find('.st-Global_Contents').outerHeight();
    // 		let maxHeight = h !== undefined ? h : '100%';
    // 		$(elem).data('maxHeight', maxHeight);
    // 	});
    // }
    MegaMenu.prototype.setEvent = function () {
        // $(window).on('resize', () => {
        // 	this.setHeight();
        // });
        var _this = this;
        // this.$elem.find('.st-Global_Body_Inner').on('transitionend', (e) => {
        // 	if ($(e.currentTarget).css('max-height') !== '0px') {
        // 		$(e.currentTarget).removeAttr('style');
        // 	};
        // });
        this.$switch.on('click', function (e) {
            e.preventDefault();
            if ($(e.currentTarget).is('[aria-selected="false"]')) {
                _this.open($(e.currentTarget).closest('.st-Global_Item')[0]);
            }
            else {
                _this.close($(e.currentTarget).closest('.st-Global_Item')[0]);
            }
            ;
        });
        this.$pmSwitch.on('click', function (e) {
            e.preventDefault();
            var target = $(e.currentTarget).data('href');
            if ($(e.currentTarget).is('.-selected')) {
                $(e.currentTarget).removeClass('-selected');
                $(target).stop(true, false).slideUp(500, 'easeOutExpo');
            }
            else {
                $(e.currentTarget).addClass('-selected');
                $(target).stop(true, false).slideDown(500, 'easeOutExpo');
            }
            ;
        });
        if (util.isTouchDevice) {
        }
        else {
            this.$elem.on('mouseenter', function (e) {
                if (util.viewport !== 'phone' && util.viewport !== 'tablet') {
                    _this.open(e.currentTarget);
                }
                ;
            }).on('mouseleave', function (e) {
                if (util.viewport !== 'phone' && util.viewport !== 'tablet') {
                    _this.close(e.currentTarget);
                }
                ;
            });
        }
        ;
        this.$elem.on('close', function (e) {
            _this.close(e.currentTarget);
        });
        this.$innerLinks.on('click', function (e) {
            e.preventDefault();
            var current = location.pathname;
            var href = $(e.currentTarget).attr('href');
            var targetHref = href.split('#')[0].replace('http://' + document.domain, '').replace('https://' + document.domain, '');
            window.open(href, '_self');
            if (targetHref === current) {
                window.setTimeout(function () {
                    location.reload();
                }, 100);
            }
            ;
        });
    };
    MegaMenu.prototype.open = function (elem) {
        $(elem).addClass('-open');
        $(elem).find('.st-Global_Body_Inner').stop(true, false).animate({
            'height': 'show'
        }, 700, 'easeOutQuart');
        $(elem).find('.st-Global_Switch').attr('aria-selected', 'true');
        // $(elem).find('.st-Global_Body_Inner').attr({
        // 	'aria-expanded': 'true',
        // 	'aria-hidden': 'false'
        // }).css('max-height', $(elem).data('maxHeight'));
    };
    MegaMenu.prototype.close = function (elem) {
        $(elem).removeClass('-open');
        $(elem).find('.st-Global_Body_Inner').stop(true, false).animate({
            'height': 'hide'
        }, 700, 'easeOutQuart');
        $(elem).find('.st-Global_Switch').attr('aria-selected', 'false');
        // $(elem).find('.st-Global_Body_Inner').attr({
        // 	'aria-expanded': 'false',
        // 	'aria-hidden': 'true'
        // }).css('max-height', $(elem).data('maxHeight'));
        // setTimeout(function () {
        // 	$(elem).find('.st-Global_Body_Inner').css('max-height', 0);
        // }, 50);
    };
    return MegaMenu;
}());
$.fn.megamenu = function () {
    var $elems = this;
    $(document).on('click', function (e) {
        if (!$(e.target).closest('#Menu').length) {
            $elems.trigger('close');
        }
        ;
    });
    return this.each(function (i, elem) {
        new MegaMenu(elem);
    });
};
