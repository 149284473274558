"use strict";
/*!
 *
 *  local-menu.js
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1(elem) {
        this.head = '.st-Local_Title';
        this.body = '.st-Local_Contents';
        this.$elem = $(elem);
        this.$clone = this.getClone();
        $('#TipicPath').after(this.$clone);
        this.setEvent(this.$elem);
        this.setEvent(this.$clone);
    }
    default_1.prototype.getClone = function () {
        var _this = this;
        var $clone = this.$elem.clone(false);
        var $ids = $clone.find('[id]');
        $ids.each(function (i, elem) {
            $(elem).attr('id', _this.getCloneID($(elem).attr('id')));
        });
        var cl = $clone.attr('class') + '-cloned';
        $clone.attr('id', this.getCloneID($clone.attr('id'))).addClass(cl);
        return $clone;
    };
    default_1.prototype.getCloneID = function (id) {
        return id + 'Clone';
    };
    default_1.prototype.setEvent = function ($elem) {
        $elem.expander({
            head: this.head,
            body: this.body,
            viewport: {
                desktop: false,
                largeDesktop: false
            }
        });
    };
    return default_1;
}());
exports.default = default_1;
