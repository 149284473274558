"use strict";
/*!
 *
 *  product-window.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
;
var text = {
    result1: {
        ja: '対象製品は',
        en: ''
    },
    result2: {
        ja: '件あります。',
        en: ' matched.'
    },
    detail: {
        ja: '製品詳細へ',
        en: 'Product page'
    },
    empty: {
        ja: '製品に関するお問い合わせはこちら',
        en: 'Inquiry about products'
    }
};
exports.default = (function (title, list) {
    var template = [];
    template.push('<section class="ps-Modal" id="MakerModal">');
    template.push('<h3 class="ps-Modal_Title">' + title + '</h3>');
    template.push('<p class="mc-Result">' + text.result1[util.lang] + '<b class="mc-Result_Num">' + list.length + '</b>' + text.result2[util.lang] + '</p>');
    if (list.length > 0) {
        template.push('<div class="prod-List">');
        list.forEach(function (val, i) {
            template.push('<section class="prod-List_Item">');
            template.push('<header class="prod-List_Header">');
            template.push('<h2 class="prod-List_Title">' + val.name + '</h2>');
            template.push('</header>');
            template.push('<div class="prod-List_Box">');
            template.push('<figure class="prod-List_Box_Thumb"><img src="' + val.img + '" alt=""></figure>');
            template.push('<div class="prod-List_Box_Body">');
            template.push('<p>' + val.summary + '</p>');
            template.push('</div>');
            template.push('</div>');
            template.push('<footer class="prod-List_Footer">');
            if (val.ec !== '') {
                template.push('<a href="' + val.ec + '" class="mc-Link" target="_blank">EC SHOPへ</a>');
            }
            ;
            template.push('<a href="' + val.url + '" class="mc-Link" target="_blank">' + text.detail[util.lang] + '</a>');
            template.push('</footer>');
            template.push('</section>');
        });
        template.push('</div>');
    }
    else {
        var url = '/inquiry/product/';
        if (util.lang === 'en') {
            url = '/en' + url;
        }
        ;
        template.push('<p class="prod-Empty"><a href="' + url + '">' + text.empty[util.lang] + '</a></p>');
    }
    ;
    template.push('</section>');
    return template.join('');
});
