"use strict";
/*!
 *
 *  tel-handler.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
;
var initial = {
    enable: 'sw-TelLink-enable',
    disable: 'sw-TelLink-disable'
};
exports.default = (function (option) {
    var $tel = $('a[href^="tel:"]');
    var setting = $.extend(initial, option);
    if (util.ua.isPhone) {
        $tel.addClass(setting.enable);
    }
    else {
        $tel.addClass(setting.disable).on('click', function (e) {
            e.preventDefault();
        });
    }
    ;
});
