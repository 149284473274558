"use strict";
/*!
 *
 *  notice.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1(id) {
        var _this = this;
        this.cookieName = 'AgreedForDaitron';
        this.isAgreed = false;
        this.expires = 7;
        this.$elem = $(id);
        if (this.$elem.length) {
            this.$btn = this.$elem.find('button');
            this.isAgreed = $.cookie(this.cookieName);
            if (!this.isAgreed) {
                this.show();
            }
            ;
            this.$btn.on('click', function (e) {
                e.preventDefault();
                _this.hide();
            });
        }
        ;
    }
    default_1.prototype.show = function () {
        this.$elem.attr('aria-hidden', 'false');
    };
    default_1.prototype.hide = function () {
        var _this = this;
        this.$elem.addClass('-hidden');
        $.cookie(this.cookieName, true, {
            expires: this.expires
        });
        setTimeout(function () {
            _this.$elem.attr('aria-hidden', 'true');
        }, 700);
    };
    return default_1;
}());
exports.default = default_1;
