"use strict";
/*!
 *
 *  menu.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var menu = /** @class */ (function () {
    function menu(elem) {
        var _this = this;
        this.$elem = $(elem);
        this.body = elem + '_Drawer';
        this.switch = elem + '_Switch';
        this.$item = this.$elem.children(elem + '_Item').has(this.body);
        this.$switch = this.$elem.find(this.switch);
        this.$body = this.$elem.find(this.body);
        this.setHeight();
        $(window).on('reisze', function () {
            _this.setHeight();
        });
        this.$body.on('transitionend', function (e) {
            if ($(e.currentTarget).is('[aria-expanded="true"]')) {
                $(e.currentTarget).removeAttr('style');
            }
            ;
        });
        // this.$elem.expander({
        // 	head: this.switch,
        // 	body: this.body,
        // 	autoClose: true,
        // 	viewport: {
        // 		phone: true,
        // 		tablet: true,
        // 		desktop: false,
        // 		largeDesktop: false
        // 	}
        // });
    }
    menu.prototype.drawer = function (item) {
        if (item) {
            item.$switch.toggleAria('selected');
            item.$body.toggleAria('expanded hidden');
        }
        ;
    };
    menu.prototype.getItem = function ($elem) {
        if ($elem.is(this.switch)) {
            var target = $elem.attr('href');
            var $target = target !== undefined ? $(target) : $('');
            return {
                $switch: $elem,
                $body: $target
            };
        }
        else {
            return {
                $switch: $elem.find(this.switch),
                $body: $elem.find(this.body)
            };
        }
        ;
    };
    menu.prototype.setHeight = function () {
        this.$body.each(function (i, elem) {
            var h = $(elem).children().outerHeight();
            var maxHeight = h !== undefined ? h : '100%';
            $(elem).data('maxHeight', maxHeight);
        });
    };
    return menu;
}());
var BuildMenu = /** @class */ (function () {
    function BuildMenu() {
        // scroller: {
        // 	[key: string]: PerfectScrollbar;
        // } = {
        // 	header: new PerfectScrollbar('.st-Header_Inner', scrollOption),
        // 	global: new PerfectScrollbar('.st-Global_Drawer_Inner', scrollOption),
        // 	local: new PerfectScrollbar('.st-Local_Drawer_Inner', scrollOption),
        // 	under: new PerfectScrollbar('.st-Under_Drawer_Inner', scrollOption)
        // };
        this.current = {
            global: undefined,
            local: undefined,
            under: undefined
        };
        this.wrapperStateClass = {
            global: 'st-Header-show-local',
            local: 'st-Header-show-under',
            under: 'st-Header-show-bottom'
        };
        this.hoverClass = {
            global: 'st-Global_Item-hover',
            local: 'st-Local_Item-hover',
            under: 'st-Under_Item-hover'
        };
        this.hoverViewport = {
            phone: false,
            tablet: false,
            desktop: true,
            largeDesktop: true
        };
        this.$wrapper = $('#Header');
        this.$menuBtn = $('#MenuBtn');
        this.$menuBody = $('#MenuBody');
        this.global = new menu('.st-Global');
        this.local = new menu('.st-Local');
        this.under = new menu('.st-Under');
        this.setEvent();
    }
    BuildMenu.prototype.setEvent = function () {
        var _this = this;
        $(window).on('viewportChanged', function () {
            _this.reset();
        });
        this.$menuBtn.on('click', function (e) {
            e.preventDefault();
            _this.$menuBtn.toggleAria('selected');
            _this.$menuBody.toggleAria('expanded hidden');
        });
        this.global.$item.on('mouseenter', function (e) {
            _this.enter('global', e);
        }).on('mouseleave', function (e) {
            _this.leave('global', e);
        });
        this.local.$item.on('mouseenter', function (e) {
            _this.enter('local', e);
        }).on('mouseleave', function (e) {
            _this.leave('local', e);
        });
        this.under.$item.on('mouseenter', function (e) {
            _this.enter('under', e);
        }).on('mouseleave', function (e) {
            _this.leave('under', e);
        });
        this.global.$switch.on('click', function (e) {
            _this.switch('global', e);
        });
        this.local.$switch.on('click', function (e) {
            _this.switch('local', e);
        });
        this.under.$switch.on('click', function (e) {
            _this.switch('under', e);
        });
        var event = util.isTouchDevice ? 'touchend' : 'click';
        $(document).on(event, function (e) {
            if (_this.current.under && !$(e.target).closest('.' + _this.under.$item[0].className).length) {
                _this.close('under', _this.current.under);
            }
            ;
            if (_this.current.local && !$(e.target).closest('.' + _this.local.$item[0].className).length) {
                _this.close('local', _this.current.local);
            }
            ;
            if (_this.current.global && !$(e.target).closest('.' + _this.global.$item[0].className).length) {
                _this.close('global', _this.current.global);
            }
            ;
            if (_this.$menuBody.is('[aria-expanded="true"]') && !$(e.target).closest('#Menu').length) {
                _this.reset();
            }
            ;
        });
    };
    BuildMenu.prototype.enter = function (type, e) {
        if (this.isHover()) {
            var $item = $(e.currentTarget);
            var current = this[type].getItem($item);
            this.open(type, current);
            $item.addClass(this.hoverClass[type]);
        }
        ;
    };
    BuildMenu.prototype.leave = function (type, e) {
        if (this.isHover()) {
            var $item = $(e.currentTarget);
            this.close(type, this.current[type]);
            $item.removeClass(this.hoverClass[type]);
        }
        ;
    };
    BuildMenu.prototype.switch = function (type, e) {
        e.preventDefault();
        var $item = $(e.currentTarget);
        if (type === 'global') {
            var current = this.global.getItem($item);
            if (this.current.global) {
                if (this.current.under) {
                    this.close('under', this.current.under);
                }
                ;
                if (this.current.local) {
                    this.close('local', this.current.local);
                }
                ;
                if (this.current.global.$switch.is(current.$switch)) {
                    this.close('global', current);
                }
                else {
                    this.close('global', this.current.global);
                    this.open('global', current);
                }
                ;
            }
            else {
                this.open('global', current);
            }
            ;
        }
        ;
        if (type === 'local') {
            var current = this.local.getItem($item);
            if (this.current.local) {
                if (this.current.under) {
                    this.close('under', this.current.under);
                }
                ;
                if (this.current.local.$switch.is(current.$switch)) {
                    this.close('local', current);
                }
                else {
                    this.close('local', this.current.local);
                    this.open('local', current);
                }
                ;
            }
            else {
                this.open('local', current);
            }
            ;
        }
        ;
        if (type === 'under') {
            var current = this.under.getItem($item);
            if (this.current.under) {
                if (this.current.under.$switch.is(current.$switch)) {
                    this.close('under', current);
                }
                else {
                    this.close('under', this.current.under);
                    this.open('under', current);
                }
                ;
            }
            else {
                this.open('under', current);
            }
            ;
        }
        ;
    };
    BuildMenu.prototype.open = function (type, item) {
        this[type].drawer(item);
        item.$body.css('max-height', item.$body.data('maxHeight'));
        this.$wrapper.addClass(this.wrapperStateClass[type]);
        this.current[type] = item;
    };
    BuildMenu.prototype.close = function (type, item) {
        this[type].drawer(item);
        item.$body.css('max-height', item.$body.data('maxHeight'));
        setTimeout(function () {
            item.$body.css('max-height', 0);
        }, 10);
        this.$wrapper.removeClass(this.wrapperStateClass[type]);
        if (this.current[type]) {
            this.current[type] = undefined;
        }
        ;
    };
    BuildMenu.prototype.reset = function () {
        var _this = this;
        var types = ['global', 'local', 'under'];
        types.forEach(function (type, i) {
            if (_this.current[type]) {
                _this.current[type].$body.removeAttr('style');
                _this.close(type, _this.current[type]);
            }
            ;
        });
        if (util.viewport === 'phone' || util.viewport === 'tablet') {
            this.$menuBtn.attr('aria-selected', 'false');
            this.$menuBody.attr({
                'aria-expanded': 'false',
                'aria-hidden': 'true'
            });
        }
        else {
            this.$menuBtn.attr('aria-selected', 'true');
            this.$menuBody.attr({
                'aria-expanded': 'true',
                'aria-hidden': 'false'
            });
        }
        ;
    };
    BuildMenu.prototype.isHover = function () {
        return !util.isTouchDevice && this.hoverViewport[util.viewport];
    };
    return BuildMenu;
}());
exports.BuildMenu = BuildMenu;
