"use strict";
/*!
 *
 *  gallery.ts
 *
 */
var objectFitImages = require('object-fit-images');
var defaultSetting = {
    main: '.main',
    nav: '.nav'
};
var Gallery = /** @class */ (function () {
    function Gallery(elem, option, index) {
        this.loader = '.sw-Loader';
        this.setting = defaultSetting;
        this.count = 2;
        this.$elem = $(elem);
        this.setting = $.extend({}, this.setting, option);
        this.$main = this.$elem.find(this.setting.main);
        this.$nav = this.$elem.find(this.setting.nav);
        this.$loader = this.$elem.find(this.loader);
        this.imgClass = 'sw-Img-' + index;
        this.index = index;
        this.init();
    }
    Gallery.prototype.init = function () {
        var _this = this;
        this.$main.addClass(this.setting.main.replace('.', '') + '-' + this.index);
        this.$nav.addClass(this.setting.nav.replace('.', '') + '-' + this.index);
        this.$nav.children().each(function (i, elem) {
            var $img = $(elem).find('img');
            $img.addClass(_this.imgClass);
            var src = $img.attr('src');
            var caption = $img.attr('alt');
            var template = [];
            template.push('<div class="' + _this.setting.main.replace('.', '') + '_Inner">');
            template.push('<div class="' + _this.setting.main.replace('.', '') + '_Img">');
            template.push('<div class="' + _this.setting.main.replace('.', '') + '_Img_Inner">');
            template.push('<img src="' + src + '" alt="" class="' + _this.imgClass + '">');
            template.push('</div>');
            template.push('</div>');
            if (caption !== '') {
                template.push('<div class="' + _this.setting.main.replace('.', '') + '_Img_Caption">' + caption + '</div>');
            }
            ;
            template.push('</div>');
            _this.$main.append(template.join(''));
        });
        this.$main.on('init', function () {
            _this.loaded();
        });
        this.$nav.on('init', function () {
            _this.loaded();
        });
        this.$main.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            fade: true,
            asNavFor: this.setting.nav + '-' + this.index,
            adaptiveHeight: true
        });
        this.$nav.slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: this.setting.main + '-' + this.index,
            arrows: false,
            dots: false,
            focusOnSelect: true,
            centerPadding: '0px',
            adaptiveHeight: true
        });
    };
    Gallery.prototype.loaded = function () {
        if (--this.count > 0)
            return false;
        objectFitImages('img.' + this.imgClass, { watchMQ: true });
        this.$loader.addClass('-hidden');
    };
    return Gallery;
}());
$.fn.gallery = function (option) {
    return this.each(function (i, elem) {
        var opt = option ? option : defaultSetting;
        new Gallery(elem, opt, i);
    });
};
